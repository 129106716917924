.loading {
  .cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .ant-spin {
    color: #3e79f7;
  }
}
.signup__code {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #1a3353;
}
.singup__code_msg {
  // width: 400px;
  // height: 373.04px;
  top: 563px;
  left: 1460px;
  padding: 5px 5px 20px 5px;
  // border-radius: 10px;
  border: 1px;
  gap: 16px;

  .text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #72849a;
  }
}
.register__subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #72849a;
  span {
    font-weight: bold;
  }
  span:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.usage__container {
  .ant-radio-group {
    all: unset;
  }
  .usage__planning__section {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    .radio {
      font-weight: 500;
      font-size: 25px;
      color: #1a3353;
    }
  }
}
.workspace__container {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .desc {
    font-size: 14px;
    color: #72849a;
  }
}
.invite__team__container {
  font-weight: 500;
  font-size: 17px;
  .send__invite {
    color: #1a3353;
  }
  .share__link {
    color: #3e79f7;
  }
  .share__link:hover {
    cursor: pointer;
  }
  .bulk {
    color: #1a3353;
  }
  .bulk:hover {
    cursor: pointer;
  }
}
.login__with__email {
  span {
    font-size: 12px;
  }
  span:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.feedback__container {
  position: absolute;
  bottom: 0px;
  right: 5px;
  z-index: 100;
  .ant-card-body {
    padding: 5px 10px;
  }
  .ant-form-item-label {
    display: none !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}
// .jodit-status-bar__item-right:nth-child(4) {
// display: none;
// }
.surfmail {
  text-decoration: underline;
}
.surfmail:hover {
  cursor: pointer;
}
.workspace__title {
  font-weight: bolder;
  // font-size: 14px;
  // line-height: 21px;
  text-align: center;
  color: #72849a;
}
.newTabLink:hover {
  text-decoration: underline;
}
.company__logo {
  .ant-upload-picture-card-wrapper {
    display: inline-block;
    width: auto;
  }
}
.ant-switch-small {
  width: 55px !important;
  height: 22px !important;
}
.ant-switch-small .ant-switch-handle {
  width: 18px !important;
  height: 18px !important;
}
.ant-switch-small .ant-switch-inner {
  font-size: 12px !important;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc((100% - 12px) - 8px) !important;
}
.ant-popover-inner-content {
  width: 300px;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.form-required-label-hidden {
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}
.table__search__highlight {
  background-color: lightblue;
  font-weight: bold;
  border-radius: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
