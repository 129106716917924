.ant-table-body {
  overflow-y: auto !important;
}
.ant-table-thead > tr > th {
  background: #e6ebf1 !important;
}
.hoverable:hover {
  cursor: pointer;
}
.ant-table-tbody > tr > td {
  border-bottom: 0px solid transparent;
}
.ant-table-pagination-right {
  align-items: baseline;
}
.easy-edit-inline-wrapper {
  width: 90%;
}
.easy-edit-component-wrapper {
  display: inline-block;
  width: 75%;
}
.easy-edit-component-wrapper input {
  width: 100%;
}
.easy-edit-button-wrapper {
  display: inline-flex !important;
  width: 25%;
}
.easy-edit-button {
  background: transparent;
}
.truncate {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  top: 2px;
  position: relative;
  height: 19px;
}
.table__header {
  // background: rgba(62, 121, 247, 0.2);
  border-radius: 20px;
  // min-height: 70px;
  width: 100%;
  margin: 10px 0;
}

.table__summary {
  width: 100%;
  margin: 0 10px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  background: #ffffff !important;
  border-radius: 20px 20px 0px 0px !important;
  margin: 0px 20px !important;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #f2f2f2 !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5) !important;
  border-bottom: none !important;
}
.ant-tabs-nav-wrap {
  min-height: 60px;
  align-items: flex-end;
}
.ant-tabs-nav-list {
  height: 75%;
}
.ant-tabs-content-holder {
  padding: 30px;
  background: #f2f2f2;
  border-radius: 0px 0px 20px 20px;
  border-top: none;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}
.policy__description {
  color: #72849a;
  font-size: 12px;
}
.policy__header {
  color: #1a3353;
  font-weight: 500;
  font-size: 21px;
  width: 270px;
}
// tooverwrite color for clickable text
.clikabe__text {
  color: #3e82f7 !important;
}
.disabled__text {
  color: #dddddd !important;
}
// To overwrite default tooltip arrow details
.ant-tooltip-arrow-content {
  border: #4f738b solid 2px;
  width: 10px !important;
  height: 10px !important;
}
// To overwrite default right tooltip arrow position
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  left: -3.0710699999999997px !important;
}
// To overwrite default left tooltip arrow position
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  right: -3.0710699999999997px !important;
}

#InnerFilterAction {
  max-height: 400px;
  width: 100%;
}
.filter__container {
  .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-content-holder {
    background: none;
    padding: 0;
  }
}
.table__total {
  display: flex;
  justify-content: flex-end;
  .ant-alert-content {
    flex: none !important;
  }
}
.application__logo {
  img:before {
    content: ' ';
    background-image: 'url("/img/SaaS-logos/default-SaaS.png")';
  }
}
.detailsTable {
  border-collapse: collapse !important;
  width: 70% !important;
  margin: auto;
  .test {
    border-bottom: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;
  }
  th,
  td {
    width: 30%;
    max-width: 350px !important;
    border: 1px solid #ddd !important;
    text-align: left;
    padding-left: 8px;
  }
}
.alerts__table {
  // to maintain the same height when the status dropdown is hidden
  .ant-table-row {
    height: 52px;
  }
}
.ant-tabs-nav-more:hover {
  cursor: pointer;
}
.disabled__container {
  opacity: 0.3;
  pointer-events: none;
}
.policy__switch {
  .ant-switch-checked {
    background-color: #47aa16;
  }
}
