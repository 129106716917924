.____menu__container {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: var(
      --Selected-gradient,
      linear-gradient(91deg, rgba(62, 121, 247, 0.1) 0%, rgba(145, 12, 250, 0.1) 100%)
    ) !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader {
  animation: rotation 0.8s linear infinite;
}
